import { debounce } from '@Core/Composables/helpers.js';
import axios from 'axios';

export default () => ({
    config: null, // suggestions or search
    open: false,
    query: '',
    industry: null,
    loading: false,
    selectedTab: '',
    results: {},
    totalHits: 0,
    total: 0,
    openResults: false,
    activeIndex: 0, // used to track the active result container for keyboard navigation

    init() {
        // Watch for changes in the viewport width and update mobile/desktop view
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                this.open = entry.contentRect.width > 768;
            }
        });
        observer.observe(document.body);

        this.$watch('openResults', (value) => {
            if (value) {
                document.addEventListener('keydown', (e) => {
                    if (e.key === 'Escape') {
                        this.openResults = false;
                    }
                });
            } else {
                document.removeEventListener('keydown', (e) => {
                    if (e.key === 'Escape') {
                        this.openResults = false;
                    }
                });
            }
        });

        // Watch for changes in the query and search
        this.$watch(
            'query',
            debounce(async (value) => {
                if (value.length) {
                    await this.search(value, this.industry);
                } else {
                    this.clearSearch();
                }
            }, 500)
        );

        // Watch for changes in the results and get the total hits
        this.$watch('results', (value) => {
            this.totalHits = Object.keys(value)
                .map((key) => value[key].total ?? value[key].length)
                .reduce((a, b) => a + b, 0);

            this.selectedTab = Object.keys(value)[0];
        });

        this.$watch('results', (value) => {
            this.total = value?.models?.nbHits + value?.manufacturers?.nbHits;
        }); // Avoid closing the result container when the click event happens within it
        document.onclick = (e) => {
            if (this.openResults && !e.target.closest('.results-container')) {
                this.openResults = false;
            }
        };
    },

    hideSearchbar(currentRoute) {
        const hideFromPages = ['homepage', 'homepage.*', 'search.index'];

        return hideFromPages.some((page) => {
            return new RegExp(page, 'g').test(currentRoute);
        });
    },

    openSearch(inputId) {
        this.open = true;
        document.body.classList.add('overflow-hidden');
        setTimeout(() => {
            document.querySelector(`#${inputId}`).focus();
        }, 10);
    },

    closeSearch() {
        this.clearSearch();
        this.open = false;
        document.body.classList.remove('overflow-hidden');
    },

    clearSearch() {
        this.query = '';
        this.results = {};
    },

    selectLabel(label) {
        this.selectedTab = label;
        setTimeout(() => {
            this.activeIndex = 0;
            this.go();
        }, 10);
    },

    go(e, direction) {
        const options = document.querySelectorAll('.search li');
        const active = document.querySelector('.search li.active');
        const activeClasses = ['active', 'bg-gray-100', 'ring-2'];

        if (options.length < 1) {
            return;
        }

        if (e?.target) {
            e.preventDefault();
        }

        if (active) {
            active.classList.remove(...activeClasses);
            active.lastElementChild.classList.remove('opacity-30');
        }

        if (direction === 'down') {
            if (this.activeIndex === options.length - 1) {
                this.activeIndex = 0;
            } else {
                this.activeIndex++;
            }
        }

        if (direction === 'up') {
            if (this.activeIndex === 0) {
                this.activeIndex = options.length - 1;
            } else {
                this.activeIndex--;
            }
        }

        options[this.activeIndex].classList.add(...activeClasses);
        options[this.activeIndex].lastElementChild.classList.add('opacity-30');
        options[this.activeIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    },

    goTo() {
        if (this.totalHits < 1) return;
        const options = document.querySelectorAll('.search li a');
        window.location.href = `${options[this.activeIndex].getAttribute('href')}`;
    },

    async submit(query, industry) {
        if (!query) return;
        window.location.href = `/guitar-values?query=${query}&industry=${industry}`;
    },

    async search(query, industry) {
        this.loading = true;
        let targetRoute = '/global-search';

        if (this.config === 'suggestions') {
            targetRoute = '/global-search/suggestions';
        }

        const response = await axios.get(`${targetRoute}?query=${query}&industry=${industry}`);
        this.results = response.data;
        this.openResults = true;
        this.loading = false;

        setTimeout(() => {
            this.activeIndex = 0;
            this.go();
        }, 10);
    }
});
